import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import Underline from "../../components/Underline";
import { H1 } from "../../components/Typography";
import useSiteMetadata from "../../components/SiteMetadata";

const seo = {
  title: "Everything about Cordycpes Militaris - Myco Forest Blog",
  description:
    "Read Myco Forest blogs to learn the benefits of Cordyceps, how to grow Cordyceps, how to consume Cordycpes etc."
};

export default () => {
  const { siteUrl } = useSiteMetadata();
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: `${siteUrl}/blog`,
          title: seo.title,
          description: seo.description,
          site_name: "MycoForest"
        }}
      />
      <section className="section">
        <div className="container">
          <H1 alignCenter>Our Blog</H1>
          <Underline />
          <div className="content">
            <BlogRoll />
          </div>
        </div>
        <style jsx>{`
          section {
            background: #ffff;
            padding: 32px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          @media (max-width: 480px) {
            section {
              padding: 32px 20px;
            }
          }
        `}</style>
      </section>
    </Layout>
  );
};
